import React, { useState, useEffect } from "react";
import { merge } from 'lodash';
import Grid from '@mui/material/Grid';
import { BaseOptionChart } from "../../charts"
import { Intem } from "../LessonList";

import Loadable from '@loadable/component'
// import Chart from "apexcharts";
const  Chart = Loadable(() => import("react-apexcharts"));

export function MainChart (props) {

  const [value, setValue] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setValue(82.5);
    }, 1200);

  }, [])

  const options = (merge(BaseOptionChart(), {

    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            fontSize: "13px"
          },
          value: {
            fontSize: "30px",
            show: true,
            formatter: function (val) {
              if (val === '' && val!==0) return 'Loading...';
              if (isNaN(val))  return "レビューなし";
              if (val === 100) return "100点";
              return val.toFixed(1) + '点';
            }
          },
          total: {
            show: false,
          },
        }
      },
      
    },
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.5,
        // gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    }, 
    hollow: {
      margin: 0,
      size: "70%",
      background: "#293450"
    },
    track: {
      dropShadow: {
        enabled: true,
        top: 2,
        left: 0,
        blur: 4,
        opacity: 0.15
      }
    },
    stroke: {
      lineCap: "round"
    },

    labels: ['総合評価'],

  }))

  return (
    <>
      <Chart options={options} series={[value]} type="radialBar" height={200} />
    </>
  )
}

export function BarChart (props) {
  const [value, setValue] = useState([0, 0, 0, 0, 0]);
  const categories = ["最悪", "悪", "普通", "良", "最良"].reverse();

  useEffect(() => {
    setTimeout(() => {
      setValue([0, 2, 4, 3, 6].reverse());
    }, 1200);
 
  }, [])
  
  const options = (merge(BaseOptionChart(),{
    chart: {
      type: 'bar',
      height: 150
    },
    tooltip: {
      marker: { show: false },
    },
    plotOptions: {
      bar: {
      //  horizontal: true, 
        barHeight: '20%', borderRadius: 2 ,
        barWidth: '60%',
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        maxHeight: 40
      },

    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        formatter: function(val) {
          return val.toFixed(0)
        }
      },
      max: 8,

    },
    legend :{
      show: false
    }
  }));

  return (
    <Chart type="bar" series={[{
      data: value,
    }]} options={options} height={160} />
  )
}


export function PointBox (props) {

  const [value, setValue] = useState([40, 22.5, 85.3, 92]);

  
  return(
    <Grid container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: 175,
        mx: "auto",
      }}
    >
      
      {
        value.map((val, index) => {
          return  (
            <Grid item key={index} xs={6} >
              <Intem percent={val} sx={{width: 80, height: 80, mx: "auto" }} />
            </Grid>
          )
        })

      }
    </Grid>
  )
} 


import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
} from '@mui/material';
import { Container, ContainerItem, SearchInput, PaperBox, ProgressBar } from "components/UIkit";
import logo from "assets/img/icons/logo.png";
import { navigate } from "gatsby";
import { MainChart, BarChart , PointBox } from ".";
import { useInView } from "react-intersection-observer"; // 1.9K gzipped
import { motion, useAnimation } from "framer-motion";
import { before, after } from "assets/img/src/";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";
import { Link } from "gatsby";

export default function () {
  const animation0 = useAnimation();    
  const animation1 = useAnimation();    

  const [inputValue, setInputValue] = useState("");
  const [ref0, inView0, entry] = useInView({ threshold: 0 });
  const [ref1, inView1] = useInView({ threshold: 0 });
  const [total, setTotal] = useState(0);

  const fetch = () => {
    axios.post('https://api.rakutan-alps.com/api/lessons/total/')
    .then(res => {
      console.log(res.data);
      setTotal(res.data.total);
    }).catch(err => {
      
    });
  }

  useEffect(() => {
    fetch();
  }, [])

  const Title = props => {
    return (
      <Typography variant="h3" textAlign={"center"} component={"h2"} 
        py={2}
        fontSize={["2.25rem","2.75rem"]}
        {...props}
        // fontWeight={"bold"}
      >
        {props.children}
     </Typography>
    )

  }

  


  const handleInputValue = e => {
    setInputValue(e.target.value);
  }

  const handleSubmit = e => {
    navigate("/lessons/", {
      state: {query: inputValue}
    });
  }

  useEffect(() => {
    if (inView0) {
      animation0.start("visible");
    } else {
      // animation.start("hidden");
    }
  }, [animation0, inView0]);

  useEffect(() => {
    if (inView1) {
      animation1.start("visible");
    }
  }, [animation1, inView1]);

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        // type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    },
    hidden: {
      y: 150,
      opacity: 0,
    },
  }
  
  return (
    <>

    <Container>
      <ContainerItem>
      <Grid
        container
        // sx={{height: "100vh"}}
      >

        <Grid item xs={12} sx={{
        }}>
          <Box>
            <Box
              sx={theme => ({
                width: "70%",
                maxWidth: "400px",
                height: "100%",
                marginX: "auto",
                // boxShadow: theme.shadows[20],
                // backgroundColor: theme.palette.grey[100],
                // backgroundColor: theme.palette.mybg.top,
                backgroundColor: "#fffd",
                borderRadius: theme.shape.borderRadius,
                mb: 2,
              })}
            >
              <img src={logo} height={"auto"} style={{ width: "100%",}} />
            </Box>
          </Box>

        </Grid>

        <Grid item xs={12} 
          sx={theme => ({
              pb: 2,
          })}
        >

          <SearchInput 
            sx={{
              px: 0,
            }}
            value={inputValue}
            onChange={handleInputValue}
            onSubmit={handleSubmit}
          />

        </Grid>

      </Grid>
      </ContainerItem>

      <ContainerItem>
        <Box sx={{
          px: 3,
          my: 2
        }}>
          <ProgressBar value={total} total={500} />
        </Box>
      </ContainerItem>

      <ContainerItem sx={{
        mt: 6,
        mb: 1,
      }} >
        
        <Title>
          履修選択を簡単に。
        </Title>

        <Typography variant="h6" textAlign={"center"} component={"h2"} >
          「楽単アルプス」は信大の授業レビューサイト
          {/* <br /> */}
          {/* レビューがグラフで一目で分かる */}
        </Typography>

      </ContainerItem>

      <ContainerItem>

      <Grid container >
        <Grid item xs={12} sx={{  my: 4,}}>
               
          <motion.div
            ref={ref0}
            animate={animation0}
            initial="hidden"
            variants={variants}
          >
            <Box sx={{
              height: 250,
              position: "relative",
            }}>

            <PaperBox sx={{
              width: 200 ,
              position: "absolute",
              left: 0,
              zIndex: 10,
              top: [20, 0],
            }}>
              <PointBox />
            </PaperBox>

            <PaperBox sx={{
              width: 200 ,
              position: "absolute",
              left: 0,
              right: 0,
              margin: "auto",
              zIndex: 15,
              top: [10, 0],
            }}>
              <BarChart />
            </PaperBox>

            <PaperBox sx={{
              width: 200 ,
              position: "absolute",
              right: 0,
              zIndex: 20,
              top: 0,
            }}>
              <MainChart />
            </PaperBox>

            </Box>


            <Box sx={{
              textAlign: "center",
            }}>

              <Button variant="contained" 
                sx={{
                  mx: "auto", py: 1.4, fontSize: "1.15rem",
                  borderRadius: 4,
                }}
                component={Link}
                to="/lessons"
                color="secondary"
                endIcon={<ArrowForwardIosIcon fontSize="small" />}
              >
                レビューを見る
              </Button>
        
            </Box>

          </motion.div>

        </Grid>
      </Grid>

      </ContainerItem>
    
      <ContainerItem sx={{my: 4}} >
        <Title>
          シラバスとの連携
        </Title>
        <Typography variant="h6" textAlign={"center"} component={"h2"} >
          現在信大シラバスのChrome拡張機能を開発中
        </Typography>

                
        <motion.div
          ref={ref1}
          animate={animation1}
          initial="hidden"
          variants={variants}
        >
          <Box sx={{
            my: 4,
            display: ["block","flex"],
            alignItems: "center",
            justifyContent: "center"
          }}
          >

          <PaperBox sx={{
            height: ["70vw", 300],
            width: ["70vw", 300],
            mx: "auto",
            overflow: "hidden",
            mb: 2,
          }}>
            <Typography variant="h5" textAlign={"center"} sx={{py:1}} >
              {"Before"}
            </Typography>
            <Box sx={{
              height: ["58vw", 260],
              width: ["68vw", 300],
              overflow: "hidden",
              mx: "auto", borderRadius: 0.5
            }}>

              <Box sx={{
                backgroundImage: `url(${before})`,
                objectFit: "cover",
                zoom: 3,
                height: [180, 260],
                backgroundSize: [180, 260],
                overflow: "hidden",
              }} />

            </Box>  
          </PaperBox>


          <PaperBox sx={{
            height: ["70vw", 300],
            width: ["70vw", 300],
            mx: "auto",
            mb: 2,
            overflow: "hidden"
          }}>
            <Typography variant="h5" textAlign={"center"} sx={{py:1}} >
              {"After!!"}
            </Typography>
            <Box sx={{
              height: ["58vw", 260],
              width: ["68vw", 300],
              overflow: "hidden",
              mx: "auto", borderRadius: 0.5
            }}>

              <Box sx={{
                backgroundImage: `url(${after})`,
                backgroundPosition: 'right 30%',
                objectFit: "cover",
                zoom: 3,
                height: [180, 260],
                backgroundSize: [180, 260],
                overflow: "hidden",
              }} />

            </Box>  
          </PaperBox>

          </Box>

        </motion.div>



      </ContainerItem>
    </Container>
    </>
  )
}